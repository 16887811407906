/* css for header */
html,
body {
  margin: 0;
  height: 100%;
  background: #dfcece;
}

.div_header {
  display: flex;
  justify-content: center;
  background: linear-gradient(to right top, rgb(211, 98, 83), rgb(89, 75, 216));
}

.header_text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  color: rgb(240, 237, 233);
}

.header_link {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: black;
}

/* ----------------------------------------------------NAV BAR---------------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Raleway);

h2 {
  vertical-align: center;
  text-align: center;
}

* {
  font-family: "Raleway";
  box-sizing: border-box;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #00BAF0;
  background: linear-gradient(to right, #8D6E63, #243b55);
  color: #FFF;
  max-height: 100px;
  padding: 2em;
}

.link {
  color: #FFF;
  text-decoration: none;
}

.menu :hover {
  border-bottom: 1px solid;
  border-color: #f3efef;
  color: #E1863D;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu>li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}


@media (max-width: 700px) {
  .menu-button-container {
    display: flex;
  }

  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  #menu-toggle~.menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  #menu-toggle:checked~.menu li {
    border: 1px solid rgb(245, 242, 242);
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .menu>li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background: linear-gradient(to right, #141e30, #243b55);
  }

  .menu>li:not(:last-child) {
    border-bottom: 1px solid rgb(252, 252, 252);
  }
}

/* -----------------------------------------Logo-------------------------------------------------------------------------- */
.logo-container {
  float: left;

}
.logo-image{
height: 80px;
}

/* ------------------------------------Info card------------------------------------------------------------ */
.info-card-container {
  padding: 0.5rem;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*-----------------------picture card---------------------------------- */
.left-card {
  min-width: 50%;
  padding: 1rem;
  /* background-color: #8D6E63; */
  background: linear-gradient(to right top, #8D6E63, #243b55);
  border-top-left-radius: 20px;
  /* border: 1px solid;
  border-color: #ffffff; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile-image {
  border: 1px solid;
  border-top-color: #fdfdfd;
  border-bottom-color: rgb(255, 255, 255);
  border-radius: 60%;
  width: 200px;
}

.left-card h2 {
  color: #f3efef;
  font-family: 'Courier New', Courier, monospace;
}

.buttons-left-card {
  padding: 0.2rem;
}

.name {
  font-size: 2rem;
}

.resume-button {
  margin-left: 0.5rem;
  width: 10rem;
  font-size: 1.3rem;
  height: 50px;
  border: none;
  outline: none;
  background: #243b55;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  color: #dfcece;
}

.resume-button:hover {
  width: 10rem;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.resume-button:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.resume-button:active:after {
  background: transparent;
}

.resume-button:hover:before {
  opacity: 1;
}

.resume-button:hover:before {
  opacity: 1;
}

.resume-button:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}


/* text card */

.right-card {
  min-width: 50%;
  /* background-color: #243b55; */
  background: linear-gradient(to right bottom, #243b55, #8D6E63);
  border-bottom-right-radius: 20px;
  /* border: 1px solid;
  border-color: #ffffff; */
  color: #ffffff;
  font-size: 2rem;
  line-height: 3rem;
  padding-top: 5rem;
}

/* what should happen when screen size decreases */
@media (max-width: 765px) {
  .info-card-container {
    flex-direction: column;
  }

  .left-card {
    background: linear-gradient(to left bottom, #8D6E63, #243b55);
  }
  .name{font-size: 1.4rem;}
  .right-card {
    background: linear-gradient(to right bottom, #243b55, #8D6E63);
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .profile-image {
    width: 150px;
  }
  .resume-button{
    width: 8rem;
    font-size: 1rem;
  }

}
/* ---------------------------------------brief introduction------------------------- */
.brief-card{
  padding: 0.5rem;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(to right top, #8D6E63, #243b55);
  margin: 0.5rem;
  padding: 1rem;
  border-top-right-radius: 20px;
  border-bottom-left-radius:20px;
  font-size: 2rem;
  line-height: 3rem;
  color: #ffffff;
}

@media(max-width:765px){
  .brief-card{
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
/* -----------------------------------------projects-------------------------------- */
.projects {
  padding: 0.5rem;
}

.project-container {
  padding: 2rem;
  /* border: 1px solid; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;

}

.project-card {
  width: 45%;
  border: 2px solid;
  border-radius: 20px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.project-card:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);

}

.project-snippet img {
  border-radius: 10px;
  box-shadow:0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(225, 108, 108, 0.19); ;
  max-width: 30rem;
  max-height: 20rem;
}

.view-project {
  position: absolute;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

.project-card:hover .view-project {
  opacity: 1;
}

.project-card:hover .project-snippet {
  opacity: 0.3;
}

.view-project button {
  border-radius: 12px;
  background-color: #243b55;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.view-project button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #767a76;
  color: white;
}

@media (max-width: 765px) {
  .project-container {
    flex-direction: column;
  }

  .project-snippet img {
    width: 18rem;
  }

  .project-card {
    width: 20rem;

  }
}

@media (max-width: 1200px) {
  .project-container {
    flex-direction: column;
  }
  .project-snippet img {
    width: 18rem;
  }
}
/* --------------------------------------this part is for soft skills ------------------------*/
.softskill-parent{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.softskill-card{
  max-width: 20rem;
  /* border: 1px solid #fff; */
  margin-top: 1rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.softskill-image img {
  max-width: 9rem;
}
/* @media (max-width: 700px){
  .softskill-parent{
    flex-direction: column;
  }
} */
/*--------------------------------------- This part is for contact.js ------------------------*/
.contact-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  background: linear-gradient(to left bottom, #8D6E63, #243b55);
  color: #dfcece;
  padding: 1rem;
  margin: 1rem;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;

}
.box-below{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(to left bottom, #8D6E63, #243b55);
  padding: 1rem;
  margin: 1rem;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.box-below h2{
  color: #dfcece;
  font-size: 2.1rem;
}
.contact-left-card {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  align-items: center;
}

.icon-container {
  display: flex;
  flex-direction: column;
}

.icons {
  margin: 3rem;
}

.contact-right-card {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  max-width: 420px;
  margin: 50px auto;
}

.feedback-input {
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid #dfcece;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.feedback-input:focus {
  border: 2px solid #243b55;
}

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

[type="submit"] {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background: #243b55;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: white;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
  font-weight: 700;
}

[type="submit"]:hover {
  background: #8D6E63;
}

input:required {
  border-color: #dfcece;
  border-width: 3px;
}

input::placeholder {
  color: rgb(252, 252, 252);
}

textarea::placeholder {
  color: rgb(252, 252, 252);
}

@media (max-width: 700px) {
  .contact-parent {
    flex-direction: column;
    align-items: center;
  }
  .contact-parent h1{
    font-size: 1.3rem;
  }
  .icon-container{
    flex-direction: row;
  }
  .icons{
    margin: 2rem;
  }
}

/*------------------------------------- about me page -----------------------------*/
.content-container {
  background: linear-gradient(to left bottom, #8D6E63, #243b55);
  color: #dfcece;
  padding: 1rem;
  margin: 1rem;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 1.5rem;
}

.timeline-conatiner {
  width: 300px;
  margin: auto;
}

.timeline-p {

  border-top: 2px dashed;
  border-color: #243b55 !important;
  margin: 0;
  padding: 30px;
}



.timeline-p:nth-child(even) {
  border-left: 2px dashed;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-right: 30px;
  padding-right: 0;
}

.timeline-p:nth-child(odd) {
  border-right: 2px dashed;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-left: 30px;
  padding-left: 0;
}

.timeline-p:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.timeline-p:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/*---------------------------------- footer ------------------------------------------*/
.footer-container {
  margin-top: 2rem;
  padding: 0.5rem;
  background: linear-gradient(to left bottom, #8D6E63, #243b55);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #dfcece;
}
@media (max-width: 700px) {
  .footer-container{
    flex-direction: column;
    align-items: center;
  }
}